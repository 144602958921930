import crypto2 from './images/crypto2.png'
import crontabManifest from './images/crontab-manifest.png'
import hierarchy1 from './images/hierarchy-1.png'
import hierarchy2 from './images/hierarchy-2.png'
import pseudoCode from './images/pseudo-code-1.png'
import pseudoCode2 from './images/pseudo-code-2.png'
import logo from './images/SVG/Asset 1.svg'
import './App.css';

function App() {
  return (
    <div className="App">
      <header>
        <img src={logo} style={{ position: 'fixed', left: '0px', width: '100px', height: 'auto' }} />
        <img src={crypto2} alt="logo" />
        <p>
          <h1>CryVerify – White Paper V 0.1</h1> 
        </p>
      </header>
      <div className="container">
      <div style={{ minWidth: '150px', margin: '0px', padding: '10px', textAlign: 'left' }}>
        David J Kamer
        October 24, 2022
      </div>
      <div>
      </div>
      <article>
        <p>A Multi-Network Timestamp Based Data Verification and Transactional</p>
        <p>System</p>
        <img src={crontabManifest} />
        <h1>Method</h1>
        <p>
        CryVerify serves a role in data verification in relation to timestamp trust through network trust. Networks are created from previous network seeds that are the current block of the network seeding the new network where the current block is defined as n2 – n1 from the transformation function where (t1 – t2)n from (t1 – t2)n/x is simplified to (n1 – n2) and we take the contrapositive to get the index or indicator of the seed of the second network (network 2) as n. The difference between network 1 and 2 provides the value derivative for network 2’s blocks in the form of cryptographic complexity as a function of the size of the series of blocks in relation to the time differential. Additional networks can define internal rules, including more complex cryptographic algorithms at the block layer, while providing a time relational proof to the original or upper layer network to create a more accurate chain of trust for all derived networks that reduces computational complexity.
        </p>
        <p>
        Two or more networks can be used to encrypt each other to create increasing complexity. Predefined meeting points on the networks’ individual time synchronization value represented by “t” for different levels of complexity to encapsulate different segments of the network at predefined intervals. This creates a proof of work (see previous definition – time complexity used to measure cycles for a predefined check point) unique for the combination of networks. The manifest can be provided as a set of predefined timestamps or a function in the form of a crontab or set of crontabs to provide complexity functions as price predictors or predefined complexity levels for derivatives. The set can be a set or series of sets. A series of sets used to create variable synchronization patterns, and a single set will re-iterate indefinitely.
        </p>
        <p>
        Network public key cryptography is used to trade data. The network public key will be defined by the network’s master node at a queryable endpoint or at a URL indicated in header information communicated on the initial handshake. Node implementation may handle certificate generation and propagation differently, including generation by the node.
        </p>
        <p>
        Random seeds are unique to each network at predefined intervals and pre-shared for the next interval(s). Meaning that random seeds, generated on the fly indicate uniqueness between timestamp hops, creating a control for complexity in direct relation to time represented by the timestamp value or (t).
        </p>
        <p>
        This schema gives cpu time a translatable value on the blockchain, giving more decision making power directly awarded to individual node owners for incremented computational cycles. The function used with the seed is either an initially agreed upon function (encryption algorithm) or an exchanged function in the header of the request from the schedule setup, and the return value is always a cryptographic key (encrypted asymmetrically) to send as a public key to the functionally sending network in the next call of the predefined schedule.
        </p>
        <p>
        Computational cost is calculated on the second iteration but limited by the time stamp using asynchronous calls cancelable by the timestamp. A similar pattern found in Kubernetes for the CronJob object is the activeDeadlineSeconds configuration option, or at a lower level, what is actually implemented as preemptive scheduling.
        </p>
        <p>
        On example of this syntax in non-JSON form:
        </p>
        <img src={crontabManifest} />
        <p>
        The sets are defined by set members that have 3 integer values with the crontab syntax translated from a defined/formatted string, the number of iterations of a specific cron syntax and a target network identifier as an integer.
        </p>
        <h2> Network Protocol </h2>
        <p>
        The following network protocol can be used with a decentralized node system. Looking at the syntax you can use Object Notation or just assume layered Objects that are connected in a hierarchical fashion like header data on a network packet or any packet data expressed in YAML.
        </p>
        <p>
        The two networks need to define an agreed upon following present to establish trust between the two networks as a distributed computing system based on binary principles of 2 dimensional computing schemas (binary placement as a defining limiter). The two networks have to find a trust definition.
        </p>
        <h2> Two asymmetric keys in a chain </h2>
        <p>
        Provide the public key to each other public key holder, each public key holder encrypts the other key in a predefined way, generating the next private key and public key but the seed is provable and a timestamp server or set of timestamp servers that provide a random seed, making the seed servers the ones that control the value.
        </p>
        <p>
        Two asymmetric keys paired with public keys, and each key pair has an associated timestamp and the longest chain of timestamps equates to the best trust but real world signer still matters for accuracy of timestamp map. Resetting for transaction accuracy defined per network.
        </p>
        <p>
        Each network can be provided a definition and require expected output making mining useful for computational purposes given known complexity with timestamp ranges used to validate computational transactions.
        </p>
        <h2> Block </h2>
        <p> The Block object is to verify computational complexity or if the computation occurred.</p>
        <h3> Object </h3>
        <img src={hierarchy1} />
        <img src={pseudoCode} />
        <p>
          Key:
        </p>
        <p>
        →/← : Network Origin to Network Terminal for computation, computation on arrow point for other origin. Assumes bidirectional communication with equal computational requirement on both sides to validate complexity.
        </p>
        <p>
        === : JavaScript Notation – true equal including data type or metadata, deep compare equivalent in this context.
        </p>
        <p>
        Public key on both machines and the Data/Payload transformed by the Header function which in secure environments can also be placed in the payload, making the return result exactly the same on both machines and servererable. Provide just the hash of the data as the payload in the public key and then it’s cryptocurrency.
        </p>
        <p>
        The private key containing the function result can be traded out of band from the generation as currency and verified with a timestamp server standard doing the same operation on the chain of metadata from the initial computation(s).
        </p>
        <p>
         Network resets on predefined timestamps divide the value of the timestamp generating network to the computational nodes based on timestamp accuracy to the timestamp server or just to the reporting node based on perceived accuracy by the timestamp server, resulting in a (legitimately) spacetime mapping unless timestamp manipulation occurs.
        </p>
        <h2> Chain </h2>
        <p>
        The Chain object is to verify results of each Block in relation to a predefined timing standard.
        </p>
        <h3> Object </h3>
        <img src={hierarchy2} />
        <img src={pseudoCode2} />
        <p> Key: </p>
        <p>  &lt;&gt; :indicates difference check. If TimeStamp(1) is greater than TimeStamp(2) then network one checks difference between computational private key or uses predefined resync function to sync.
        </p>
        <p>
        The Chain object is used to create a set of one or more time servers that result in cryptographic public keys that denote ownership of something at some point in time in relation to the set of networks or time servers reporting the result of a computational result at a specific time in a network defined by the initial communication of nodes on a trust basis by the defining following present of the network defined by the rate of desync of two networks in the chain object (TimeStamp(1) &lt;&gt;TimeStamp(2)) to denote the rate of desync.
        </p>
        </article>
        </div>
        </div>
  );
}

export default App;
